<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          color="error"
          class="mb-4 me-3"
          @click="$router.push({ name: 'api-keys-list' })"
        >
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
          <span>{{ $t('btn.back') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <p class="mt-2">
          <b>Proveedores</b>
        </p>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addProveedorApi()"
            >
              <v-icon small>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <APIProveedorData
        v-for="(proveedor, indP) in proveedorApis"
        :key="indP"
        :pos="indP"
        :item="proveedor"
        :proveedores="proveedores"
        :api="api"
        @setApi="setApi"
      />
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiClose, mdiEyeOffOutline, mdiEyeOutline, mdiArrowLeft,
} from '@mdi/js'

import APIProveedorData from './APIProveedorData.vue'

export default {
  components: {
    APIProveedorData,
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
    proveedores: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stepForm: null,
      icons: {
        mdiClose,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiArrowLeft,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      currencies: [],
      isPasswordVisible: false,
      itemsProveedor: [],
      searchProveedor: null,
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      proveedorApis: state => state.app.proveedorApis,
    }),
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  created() {
    if (this.api.proveedores.length > 0) {
      const rrr = this.api.proveedores.sort((a, b) => a.proveedor_slug - b.proveedor_slug)
      this.updateProveedorApi(rrr)
    } else {
      this.updateProveedorApi([])
    }
  },
  methods: {
    ...mapMutations(['deleteProveedorApi', 'updateProveedorApi', 'addProveedorApi', 'updateClientApi', 'addClientApi']),
    setApi() {
      this.$emit('getAPI')
    },
  },
}
</script>
